//         "zen": {
//             label: "Zen",
//             levelType: "ZEN",
//             levelFn: calcLevelZen,
//             exercises: [
//                 "zen_csigaver",
//                 "zen_kukucska",
//                 "zen_kapaszkodos",
//                 "zen_lelegzos",
//                 "zen_ujj",
//                 "zen_dudolos",
//                 "zen_hintazos",
//                 "zen_belsobeke",
//                 "zen_updown",
//                 "zen_flower",
//                 "zen_tree",
//                 "zen_tetris"

//             ],

import { calcLevelZen } from './calc_level_zen';

export const zen_data = {
    zen: {
        label: "Zen",
        levelType: "ZEN",
        levelFn: calcLevelZen,
        exercises: {

            zen_csigaver: {
                label: "Csigavér",
                level: 1,
                eda: true,

                type: "Waves",
                params: {
                    duration: 300000,
                    backgroundImage: "/media/00szinkombinacioJO_5.jpg",

                    point_mult: 1,
                    // l_incr: 1,
                    // l_decr: 1,
                    // r_incr: 1,
                    // r_decr: 1
                },

            },

            zen_kukucska: {
                label: "Kukucska",
                level: 2,
                eda: true,


                type: "Waves",
                params: {
                    duration: 300000,
                    backgroundImage: "/media/kukucska.png",
                    introLabel: "Csukott szemmel próbáld, néha kikukucskálva!",
                    introTime: 20000,

                    point_mult: 1,
                    // l_incr: 1,
                    // l_decr: 1,
                    // r_incr: 1,
                    // r_decr: 1
                },

            },

            zen_kapaszkodos: {
                label: "Kapaszkodós",
                level: 3,
                eda: true,


                type: "Waves",
                params: {
                    duration: 300000,
                    backgroundImage: "/media/megkapaszkodos.png",
                    introLabel: "Keress egy pontot a képen és csak oda nézz!",
                    introTime: 20000,

                    point_mult: 1,
                    // l_incr: 1,
                    // l_decr: 1,
                    // r_incr: 1,
                    // r_decr: 1
                },

            },

            zen_lelegzos: {
                label: "Lélegzős",
                level: 4,
                eda: true,


                type: "Waves",
                params: {
                    duration: 300000,
                    backgroundImage: "/media/lelegzos.png",
                    introLabel: "Mondd lassan magadban és közben csináld is: Beszívom a levegőt és kifújom a levegőt!",
                    introTime: 20000,

                    point_mult: 1,
                    // l_incr: 1,
                    // l_decr: 1,
                    // r_incr: 1,
                    // r_decr: 1
                },

            },

            zen_ujj: {
                label: "Ujjbüngyürgetős",
                level: 5,
                eda: true,
                type: "Waves",
                params: {
                    duration: 300000,
                    backgroundImage: "/media/ujjbungyurgetos.png",
                    introLabel: "Érintsd össze a hüvelyk és mutató ujjadat és körözz lassan!",
                    introTime: 20000,

                    point_mult: 1,
                    // l_incr: 1,
                    // l_decr: 1,
                    // r_incr: 1,
                    // r_decr: 1
                },

            },

            zen_dudolos: {
                label: "Dúdolós",
                level: 6,
                eda: true,
                type: "Waves",
                params: {
                    duration: 300000,
                    backgroundImage: "/media/dudolos.png",
                    introLabel: "Dúdold magadban a kedvenc dalodat!",
                    introTime: 20000,

                    point_mult: 1,
                    // l_incr: 1,
                    // l_decr: 1,
                    // r_incr: 1,
                    // r_decr: 1
                },

            },


            zen_hintazos: {
                label: "Hintázós",
                level: 7,
                eda: true,
                type: "Waves",
                params: {
                    duration: 300000,
                    backgroundImage: "/media/hintazos.png",
                    introLabel: "Keress két pontot a képen és lassan hintázz köztük a szemeddel!",
                    introTime: 20000,

                    point_mult: 1,
                    // l_incr: 1,
                    // l_decr: 1,
                    // r_incr: 1,
                    // r_decr: 1
                },

            },

            zen_belsobeke: {
                label: "Belső béke",
                level: 8,
                eda: true,
                type: "Waves",
                params: {
                    duration: 40000,
                    backgroundImage: "/media/00szinkombinacioJO_5.jpg",

                    introLabel: "40 másodperced van, hogy megszelidítsd a hullámokat!",
                    introTime: 20000,

                    point_mult: 1,
                    // l_incr: 1,
                    // l_decr: 1,
                    // r_incr: 1,
                    // r_decr: 1
                },

            },

            zen_updown: {
                label: "Fel-Le",
                level: 9,
                eda: true,
                type: "WaveUpdown",
                params: {
                    backgroundImage: "/media/moon.jpg",
                    visual: {
                        incr: ["/media/incr/fire1.jpg", "/media/incr/fire2.jpg", "/media/incr/fire3.jpg", "/media/incr/fire4.jpg", "/media/incr/fire5.jpg", "/media/incr/fire6.jpg",],
                        decr: ["/media/decr/water1.jpg", "/media/decr/water2.jpg", "/media/decr/water3.jpg", "/media/decr/water4.jpg", "/media/decr/water5.jpg", "/media/decr/water6.jpg", "/media/decr/water7.jpg", "/media/decr/water8.jpg",],
                    },
                    duration: 180000,
                    point_mult: 1,
                    // l_incr: 2,
                    // l_decr: 1,
                    // r_incr: 2,
                    // r_decr: 1,

                },

            },

            zen_flower: {
                label: "Kisvirág",
                level: 9,
                eda: true,
                type: "Waves",
                params: {
                    flower: true,
                    backgroundImage: "/media/tree.jpg",
                    duration: 300000,
                    point_mult: 1,
                    // l_incr: 1,
                    // l_decr: 1,
                    // r_incr: 1,
                    // r_decr: 1
                },

            },

            zen_tree: {
                label: "Fácska",
                level: 10,
                eda: true,
                type: "Waves",
                params: {
                    tree: true,
                    backgroundImage: "/media/desert4.jpg",
                    duration: 600000,
                    point_mult: 1,
                    // l_incr: 2,
                    // l_decr: 1,
                    // r_incr: 2,
                    // r_decr: 1
                },

            },


            zen_tetris: {
                label: "Tetris",
                level: 10,
                eda: true,
                type: "Tetris",
                params: {
                    point_mult: 1,
                    // l_incr: 1,
                    // l_decr: 1,
                    // r_incr: 1,
                    // r_decr: 1,

                },

            },


        }
    }

}

    // exp_climate_n: {
    //     label: "Reka N",
    //     levelType: "CTW",
    //     level: 1,
    //     eda: true,

    //     type: "Waves2Phase",
    //     params: {
    //         tree: false,
    //         hideWaves: true,
    //         // duration: 10200000,
    //         // backgroundImage: "/media/00szinkombinacioJO_5.jpg",

    //         backgroundSound: "./media/climate/Neutralis.m4a",
    //         backgoundSoundNoloop: true,

    //         point_mult: 1,
    //         l_incr: 1,
    //         l_decr: 1,
    //         r_incr: 1,
    //         r_decr: 1
    //     },

    // },

    // exp_climate_p: {
    //     label: "Reka P",
    //     levelType: "CTW",
    //     level: 1,
    //     eda: true,

    //     type: "Waves2Phase",
    //     params: {
    //         tree: false,
    //         hideWaves: true,

    //         // backgroundImage: "/media/00szinkombinacioJO_5.jpg",
    //         backgroundSound: "./media/climate/Puha.m4a",
    //         backgoundSoundNoloop: true,

    //         point_mult: 1,
    //         l_incr: 1,
    //         l_decr: 1,
    //         r_incr: 1,
    //         r_decr: 1
    //     },
    // exp_climate_k: {
    //     label: "Reka K",
    //     levelType: "CTW",
    //     level: 1,
    //     eda: true,

    //     type: "Waves2Phase",
    //     params: {
    //         tree: false,
    //         hideWaves: true,
    //         // backgroundImage: "/media/00szinkombinacioJO_5.jpg",
    //         backgroundSound: "./media/climate/Kemeny.m4a",
    //         backgoundSoundNoloop: true,

    //         point_mult: 1,
    //         l_incr: 1,
    //         l_decr: 1,
    //         r_incr: 1,
    //         r_decr: 1
    //     },

    // },

    // exp_andrei_1: {
    //     label: "Andrei 1",
    //     levelType: "CTW",
    //     level: 1,
    //     eda: true,

    //     type: "Waves2Phase",
    //     params: {
    //         tree: false,
    //         duration: 120000,
    //         backgroundImage: "/media/andrei.jpg",
    //         point_mult: 1,
    //         l_incr: 1,
    //         l_decr: 1,
    //         r_incr: 1,
    //         r_decr: 1
    //     },
    // },


    // exp_andrei_2: {
    //     label: "Andrei 2",
    //     levelType: "CTW",
    //     level: 1,
    //     eda: true,

    //     type: "Waves2Phase",
    //     params: {
    //         tree: false,
    //         hideWaves: true,
    //         duration: 120000,
    //         backgroundImage: "/media/andrei.jpg",
    //         point_mult: 1,
    //         l_incr: 1,
    //         l_decr: 1,
    //         r_incr: 1,
    //         r_decr: 1
    //     },
    // },

    // exp_andrei_3: {
    //     label: "Andrei 3",
    //     levelType: "CTW",
    //     level: 1,
    //     eda: true,

    //     type: "Waves2Phase",
    //     params: {
    //         tree: true,
    //         growSpeed: 0.5,
    //         duration: 120000,
    //         backgroundImage: "/media/andrei.jpg",
    //         point_mult: 1,
    //         l_incr: 1,
    //         l_decr: 1,
    //         r_incr: 1,
    //         r_decr: 1
    //     },
    // },

    // exp_tetris: {
    //     label: "T",
    //     levelType: "CTW",
    //     level: 1,
    //     eda: true,

    //     type: "Tetris",
    //     params: {
    //         // tree: true,
    //         // growSpeed: 0.5,
    //         duration: 120000,
    //         // backgroundImage: "/media/andrei.jpg",
    //         point_mult: 1,
    //         l_incr: 1,
    //         l_decr: 1,
    //         r_incr: 1,
    //         r_decr: 1
    //     },
    // },

                // exp_belsobeke: {
            //     label: "Belső béke",
            //     levelType: "ZEN",
            //     level: 1,
            //     eda: true,


            //     type: "WaveBasic",
            //     params: {
            //         duration: 40000,
            //         backgroundImage: "/media/00szinkombinacioJO_5.jpg",

            //         point_mult: 1,
            //         l_incr: 1,
            //         l_decr: 1,
            //         r_incr: 1,
            //         r_decr: 1
            //     },

            // },


    // kids_ctw_sounds: {
    //     label: "CTW Kids Sounds (beginner)",
    //     levelType: "CTWKIDS",
    //     level: 5,
    //     eda: true,
    //     adjustVolume: true,


    //     type: "Waves2Phase",
    //     params: {
    //         tree: true,
    //         backgroundImage: "/media/cello.jpg",
    //         duration: 300000,
    //         point_mult: 1,
    //         l_incr: 1,
    //         l_decr: 1,
    //         r_incr: 1,
    //         r_decr: 1,

    //         //backgroundSound: "./media/calm.mp3",
    //         disturb: [
    //             {
    //                 level: 60,
    //                 hist: 10,
    //                 sound: "./media/click.mp3"
    //             },
    //             {
    //                 level: 40,
    //                 hist: 10,
    //                 sound: "./media/baby1.mp3"
    //             },
    //             {
    //                 level: 20,
    //                 hist: 10,
    //                 sound: "./media/alert.mp3"

    //             },
    //             {
    //                 level: 5,
    //                 hist: 10,
    //                 sound: "./media/baby2_short.mp3",

    //             }



    //         ],
    //     },

    // },

